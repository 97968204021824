



const settings = {};

const paths = {};
paths.home = "/";
paths.signIn = "/sign_in";
paths.signInVerification = "/sign_in_verification/:verificationToken";
paths.signInVerified = "/sign_in_verification/complete"
paths.signUp = "/sign_up";
paths.signOut = "/sign_out";
paths.accountDetails = "/account/edit";
paths.accountBillingDetailsLink = "/account/billing_information";
paths.accountManageTeamLink = "/account/team";
paths.changePassword = "/account/change_password";
paths.twoFactor = "/account/two_factor";
paths.confirm = "/confirm";
paths.resetPassword = "/password_resets/:resetToken";
paths.requestPasswordReset = "/reset_password";
paths.passwordResetRequired ="/password_reset_required";
paths.recoverUsernames = "/recover_usernames";
paths.disconnectOauth = "/account/disconnect_oauth";
paths.googleOauth = "/auth/google_oauth2";
paths.googleSignIn = "/google/authenticate";
paths.googleSignUpUnavailable = "/google/sign_up_unavailable";
paths.googleConnectFailure = "/google/connect_failure";
paths.googleAccessDisabled = "/google/access_disabled";
paths.googleConfirmEmail = "/google/confirm_email";
paths.googleError = "/google/error";
paths.admin = "/admin";
paths.twoFactorCodes = "/account/two_factor/backup_codes";
paths.twoFactorDownload = "/account/two_factor/download";
paths.termsOfUse = "/terms_of_use";
paths.sidekiqWeb = "/admin/sidekiq";
paths.facebookOauth = "/auth/facebook";
paths.facebookSignIn = "/facebook/authenticate";
paths.facebookSignUpUnavailable = "/facebook/sign_up_unavailable";
paths.facebookConnectFailure = "/facebook/connect_failure";
paths.facebookAccessDisabled = "/facebook/access_disabled";
paths.facebookConfirmEmail = "/facebook/confirm_email";
paths.facebookError = "/facebook/error";
paths.samlSignUpUnavailable = "/saml/sp/sign_up_unavailable";
paths.samlConnectFailure = "/saml/sp/connect_failure";
paths.samlAccessDisabled = "/saml/sp/access_disabled";
paths.samlConfirmEmail = "/saml/sp/confirm_email";
paths.samlError = "/saml/sp/error";

paths.appleSignIn = "/apple/authenticate";
settings.paths = paths;

const externalUrls = {};
externalUrls.envatoPrivacyPolicy = "https://envato.com/privacy";
externalUrls.envatoPersonalInformation = "https://www.envato.com/privacy/#my-personal-information";
externalUrls.envatoCookies = "https://www.envato.com/cookies";
externalUrls.combinedTerms = "https://account.envato.com/terms_of_use";
externalUrls.envatoAbout = "https://envato.com/about";
externalUrls.envatoForums = "https://forums.envato.com"
externalUrls.marketHelp = "https://help.market.envato.com";
externalUrls.newSupportTicket = "https://help.market.envato.com/hc/en-us/requests/new?ticket_form_id=133406";
externalUrls.marketTerms = "https://themeforest.net/legal/market";
externalUrls.elementsLicenseTerms = "https://elements.envato.com/license-terms";
externalUrls.elementsPricing = "https://elements.envato.com/pricing";
externalUrls.elementsEnterprisePricing = "https://elements.envato.com/pricing/enterprise";
externalUrls.elementsTerms = "https://elements.envato.com/user-terms";
externalUrls.elementsUpdatePaymentDetails = "https://secure.elements.envato.com/update-payment-details?returnUrl=https://account.envato.com/";
externalUrls.tutsTerms = "https://tutsplus.com/terms-of-use";
externalUrls.placeitTerms = "https://placeit.net/terms-of-use";
externalUrls.labsTerms = "https://labs.envato.com/terms";
externalUrls.authy = "https://authy.com/";
externalUrls.googleAuthenticator = "https://support.google.com/accounts/answer/1066447";
externalUrls.oauthHelp = "https://help.market.envato.com/hc/en-us/articles/4403946101785";
// Social accounts
externalUrls.twitterAccount = "https://twitter.com/envato";
externalUrls.facebookAccount = "https://www.facebook.com/envato/";
externalUrls.youtubeAccount = "https://www.youtube.com/user/Envato";
externalUrls.instagramAccount = "https://www.instagram.com/envato/";
externalUrls.pinterestAccount = "https://www.pinterest.com.au/envato";
externalUrls.tiktokAccount = "https://www.tiktok.com/@envato";
externalUrls.linkedinAccount = "https://www.linkedin.com/company/envato/";
externalUrls.threadsAccount = "https://www.threads.net/@envato";
settings.externalUrls = externalUrls;

window.__SETTINGS__ = settings;
